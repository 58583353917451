import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import config from "../configs/config.json";

import arTranslation from '../locales/ar.json';
import enTranslation from '../locales/en.json';
import esTranslation from '../locales/es.json';
import faTranslation from '../locales/fa.json';
import frTranslation from '../locales/fr.json';
import hiTranslation from '../locales/hi.json';
import ptTranslation from '../locales/pt.json';
import ruTranslation from '../locales/ru.json';
import soTranslation from '../locales/so.json';
import tlTranslation from '../locales/tl.json';
import ukTranslation from '../locales/uk.json';
import viTranslation from '../locales/vi.json';
import zhTranslation from '../locales/zh.json';
import micmacTranslation from '../locales/micmac.json';

const resources = {
  ar: { translation: arTranslation },
  en: { translation: enTranslation },
  es: { translation: esTranslation },
  fa: { translation: faTranslation },
  fr: { translation: frTranslation },
  hi: { translation: hiTranslation },
  pt: { translation: ptTranslation },
  ru: { translation: ruTranslation },
  so: { translation: soTranslation },
  tl: { translation: tlTranslation },
  uk: { translation: ukTranslation },
  vi: { translation: viTranslation },
  zh: { translation: zhTranslation },
  micmac: { translation: micmacTranslation }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: localStorage.getItem('lang') || window.navigator.language.substring(0, 2) || config.defaultLanguage, // user selected language || user's browser language || default language
    fallbackLng: config.defaultLanguage, // if can't find the language, fall back to this language
    interpolation: {
      escapeValue: false 
    }
  });

export default i18n;
